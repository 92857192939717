import React from "react";
import profileiamge from "../image/_MG_7608-removebg-preview.png";

function ProfileImage(props) {
  return (
    <>
      <div>
        <img src={profileiamge} alt="koffiHessou" />
      </div>
    </>
  );
}

export default ProfileImage;
